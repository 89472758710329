import React, { Component } from "react";
import { Button, Col, Row, Nav, Navbar } from "react-bootstrap";
//import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
//import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import logo from '../assets/logo.png';
//import Home from "../Pages/Home";
//import About from "../Pages/About";
//import Contacts from "../Pages/Contacts";
//import Blog from "../Pages/Blog";
import ModalBox from "./ModalBox";

export default class Header extends Component {

    render(){
        return (
            <>
            <header>
                <Navbar collapseOnSelect expand="md">
                    <div className="containerBoxFull">
                        <Row>
                            <Col xl={3} lg={2} md={2} xs={6}>
                                <Navbar.Brand href="/">
                                    <img 
                                        src={logo}
                                        width="240"
                                        height="80"
                                        className="d-inline-block align-top header-logo"
                                        alt="Logo"
                                    />
                                </Navbar.Brand>
                            </Col>
                            <Col xl={9} lg={10} md={10} xs={6} className="text-right-767">
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav">
                                    <Nav className="ml-auto">
                                        <Nav.Link href="/#about">О компании</Nav.Link>
                                        <Nav.Link href="/#advantages">Преимущества</Nav.Link>
                                        <Nav.Link href="/#specialization">Специализация</Nav.Link>
                                        <Nav.Link href="/#reviews">Отзывы</Nav.Link>
                                        <Nav.Link href="/#contacts">Контакты</Nav.Link>
                                    </Nav>
                                   {/*<Button onClick={ModalBox.handleModal}>Бесплатная консультация</Button>*/} 
                                   <a href="#mailer" className="btn">Бесплатная консультация</a>
                                </Navbar.Collapse>
                            </Col>
                        </Row>
                    </div>
                </Navbar>
                
                {/*<Router>
                    <Routes>
                        <Route path="/" element={<Home />}></Route>
                        <Route path="/about" element={<About />}></Route>
                        <Route path="/contacts" element={<Contacts />}></Route>
                        <Route path="/blog" element={<Blog />}></Route>
                    </Routes>
                </Router>*/}  
            </header>            
            </>
        )
    }
}