import emailjs from "emailjs-com"
import { Form, FormControl, FormGroup, FormLabel, Button } from "react-bootstrap";

const Mailer = () => {
    function sendEmail(e) {
        e.preventDefault();
        /*emailjs.sendForm*/
    }

    return (
        <div className="main-form__wrap" id="mailer">
            <Form className="form-inline" onSubmit={sendEmail}>
                <FormGroup controlId="formBasicName">
                    <FormControl type="text" placeholder="Ваше имя" />
                </FormGroup>
                <FormGroup controlId="formBasicPhone">
                    <FormControl type="text" placeholder="Телефон" />
                </FormGroup>
                <div className="btn-wrap"><Button variant="primary" type="submit">Оставить заявку</Button></div>
            </Form>
        </div>
    )
}

export default Mailer;