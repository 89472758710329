import React, { Component } from "react";
import { YMaps, Map, Placemark } from 'react-yandex-maps';

//const mapState = { center: [55.76, 37.64], zoom: 10 };

export default class YandexMap extends Component {
    /*constructor(){
        super();
        this.mapState = {
            center: [55.76, 37.64], zoom: 10
        }
    }*/
    //mapState = { center: [55.76, 37.64], zoom: 10 };

    render(){
        return (
            <YMaps>
                <Map defaultState={{ center: [59.984467, 30.257532], zoom: 15 }} width="100%" height="500px" >
            
                <Placemark
                    geometry={[59.984467, 30.257532]}
                    properties={{
                        hintContent: 'Rudakoff',
                        balloonContent: '197183, г. Санкт-Петербург, Липовая аллея, д. 9, лит. А , пом. 23Н'
                    }}
                    options={{
                        title: 'Rudakoff',
                        iconLayout: 'default#image',
                       //iconImageHref: 'images/myIcon.gif',
                        //iconImageSize: [30, 42],
                        //iconImageOffset: [-3, -42]
                    }}
                    modules={
                        ['geoObject.addon.balloon', 'geoObject.addon.hint']
                    }
                />
            
                </Map>
          </YMaps>
        )
    }
}