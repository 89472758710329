import React, { Component } from "react";

export default class StagesBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative stagesBox">
                    <div className="text-rotate">Как мы работаем</div>
                    <div className="containerBox">
                        <h2>Этапы автомобильных перевозок</h2>
                        <p className="bold-text">Перевозка автомобильным транспортом от "Грузоперевозки" состоит из этапов:</p>
                        <ul className="stages">
                            <li>Отправка запроса менеджеру. В ближайшее время он связывается с вами.</li>
                            <li>Обсуждение деталей заказа, расчет грузоперевозки.</li>
                            <li>Подписание договора о сотрудничестве. В нем прописаны обязательства обеих сторон.</li>
                            <li>Разработка маршрута. Выполняется согласование в контролирующих органах.</li>
                            <li>Подготовка к автоперевозке. Груз упаковывают, маркируют, загружают в транспорт.</li>
                            <li>Доставка груза. Выполняется в оговоренные сроки, без опозданий.</li>
                            <li>Информирование клиента о прибытии груза.</li>
                            <li>Получение груза клиентом.</li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}