import React, { Component } from "react";
import { Row, Col} from "react-bootstrap";
import imgTopIcon1 from '../assets/top-icon-1.png';
import imgTopIcon2 from '../assets/top-icon-2.png';
import imgTopIcon3 from '../assets/top-icon-3.png';
import imgTopIcon4 from '../assets/top-icon-4.png';
import imgTopIcon5 from '../assets/top-icon-5.png';
import Mailer from "./Mailer";

export default class MainBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative main-box">
                    <div className="containerBox">
                       <h1>Автомобильные перевозки<br /> наливных грузов</h1>
                       <p>Транспортно-логистическая компания специализируется на доставке опасных и наливных грузов по России. 
                           Специалисты полностью организуют логистику грузоперевозки. Предоставляем полный комплекс услуг: 
                           хранение, погрузка и разгрузка, организация сопровождения, оформление разрешительных документов.</p>
                        <Row>
                            <Col md="1" className="text-center"></Col>
                            <Col md="2" className="text-center">
                                <img 
                                    src={imgTopIcon1}
                                    width="57"
                                    height="40"
                                    className="d-block m-auto"
                                    alt="imgTopIcon1"
                                />
                                <span>Свидетельство ДОПОГ</span>
                            </Col>
                            <Col md="2" className="text-center">
                                <img 
                                    src={imgTopIcon2}
                                    width="52"
                                    height="40"
                                    className="d-block m-auto"
                                    alt="imgTopIcon2"
                                />
                                <span>Фиксированная цена перевозки</span>
                            </Col>
                            <Col md="2" className="text-center">
                                <img 
                                    src={imgTopIcon3}
                                    width="33"
                                    height="40"
                                    className="d-block m-auto"
                                    alt="imgTopIcon3"
                                />
                                <span>Полный пакет документов</span>
                            </Col>
                            <Col md="2" className="text-center">
                                <img 
                                    src={imgTopIcon4}
                                    width="56"
                                    height="27"
                                    className="d-block m-auto"
                                    alt="imgTopIcon4"
                                />
                                <span>Мультимодальное оборудование</span>
                            </Col>
                            <Col md="2" className="text-center">
                                <img 
                                    src={imgTopIcon5}
                                    width="50"
                                    height="29"
                                    className="d-block m-auto"
                                    alt="imgTopIcon5"
                                />
                                <span>Ускоренная доставка</span>
                            </Col>
                            <Col md="1" className="text-center"></Col>
                        </Row>
                        <Mailer />
                    </div>
                </div>
            </>
        )
    }
}