import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../assets/1.jpeg";
import img2 from "../assets/2.jpg";
import img3 from "../assets/3.jpg";

export default class CarouselBox extends Component {
    render(){
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img1}
                        alt="1"
                    />
                    <Carousel.Caption>
                        <h3>Img 1</h3>
                        <p>sdvsb dvsdas asfb asfdba sfb asbf as bfa sbf abs</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img2}
                        alt="2"
                    />
                    <Carousel.Caption>
                        <h3>Img 2</h3>
                        <p>sdvsb dvsdas asfb asfdba sfb asbf as bfa sbf abs</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={img3}
                        alt="3"
                    />
                    <Carousel.Caption>
                        <h3>Img 3</h3>
                        <p>sdvsb dvsdas asfb asfdba sfb asbf as bfa sbf abs</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        )
    }
}