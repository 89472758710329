import React, { Component } from "react";
import { Container, Nav, Row, Tab, Col, Carousel, Button } from "react-bootstrap";

export default class AboutBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative" id="about">
                    <div className="text-rotate">О компании</div>
                    <div className="containerBox">
                        <h2>Полный комплекс услуг в сфере грузоперевозок</h2>
                        <div className="containerBox-874">
                            <p>Сотрудничество с нашей компанией – это выгодно и удобно. Для выполнения операций, 
                                связанных с доставкой груза, искать исполнителей на стороне не понадобится. 
                                Все, что нужно, выполнят сотрудники нашей компании. Мы организуем хранение наливных 
                                и опасных грузов в России, проверку и контроль свидетельств ДОПОГ, санитарных сертификатов, 
                                страхование грузов. Также мы тщательно подготавливаем транспорт и емкости для доставки груза, 
                                занимаемся погрузкой и разгрузкой, сопровождением товаров клиента. Мы экономим ваше время 
                                и гарантируем качество исполнения заказов любой сложности.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}