import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";

import imgmap from '../assets/map.png';

export default class CountryMapBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative country-map">
                    <div className="text-rotate">Направления перевозок</div>
                    <div className="containerBox">
                        <h2>Работаем по всей России и ближнему зарубежью</h2>
                        <Row>
                            <Col md="5">
                                <div className="box-s">
                                    <div className="table-wrap">
                                        <table>
                                            <thead>
                                                <tr><th colSpan="2">Доставка из Москвы</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Санкт-Петербург</td><td>1 день</td></tr>
                                                <tr><td>Волгоград</td><td>1-2 дней</td></tr>
                                                <tr><td>Казань</td><td>1 день</td></tr>
                                                <tr><td>Новосибирск</td><td>5 дней</td></tr>
                                                <tr><td>Владивосток</td><td>14-20 дней</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <a href="#mailer" className="btn-yellow btn">Заказать перевозку</a>
                                </div>
                            </Col>
                            <Col md="7">
                                <img 
                                    src={imgmap}
                                    width="709"
                                    height="273"
                                    alt="Направления перевозок"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}