import React, { Component } from "react";
import AdvantagesBox from "./AdvantagesBox";
import ContactsBox from "./ContactsBox";
import CountryMapBox from "./CountryMapBox";
import ReviewsBox from "./ReviewsBox";
import SpecializationBox from "./SpecializationBox";
import StagesBox from "./StagesBox";

export default class MainPageBox extends Component {
    render(){
        return (
            <div>
                <AdvantagesBox />
                <SpecializationBox />
                <StagesBox />
                <CountryMapBox />
                <ReviewsBox />
                <ContactsBox />               
            </div>
        )
    }
}