import React, { Component } from "react";
import { Container } from "react-bootstrap";

export default class HeaderTop extends Component {
    render(){
        return (
            <>
            <div className="top-header">
                <div className="containerBoxFull">
                    <div className="top-header__content">
                        <div className="ml-auto text-right">
                            {/*<div className="d-inline-block align-top"><a href="tel:+78127772222">+7(812)777-22-22</a></div>
                            <div className="d-inline-block align-top"><a href="tel:+78127773355">+7(812)777-33-55</a></div>*/}
                            <div className="d-inline-block align-top"><span className="time-work">9:00-20:00 ежедневно</span></div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}