import React, { Component } from "react";
import { Container, Nav, Row, Tab, Col, Carousel } from "react-bootstrap";
import CarouselBox from "./CarouselBox";

export default class ReviewsBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative" id="reviews">
                    <div className="text-rotate">Отзывы</div>
                    <div className="containerBox">
                        <Row>
                            <Col md="6">
                                <p className="title-p">С гордостью публикуем отчеты об успешных проектах и реальные отзывы заказчиков</p>
                            </Col>
                            <Col md="6">
                                <CarouselBox />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}