import React, { Component } from "react";
import MainBox from "./MainBox";
import AboutBox from "./AboutBox";

export default class MainPageBoxTop extends Component {
    render(){
        return (
            <>
                <MainBox />
                <AboutBox />
            </>
        )
    }
}
