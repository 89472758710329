import React, { Component } from "react";

export default class ButtonScrollToTop extends Component {
    _scrollToTop() {
        //let scroll = document.getElementById('scrolledBlock');
        //scroll.scrollTop = 0;

        var goTopBtn = document.querySelector('.arrow-scroll-to-top');

        window.addEventListener('scroll', this.trackScroll);
        goTopBtn.addEventListener('click', this.backToTop);
    }

    
   trackScroll() {
        var scrolled = window.pageYOffset;
        var coords = document.documentElement.clientHeight;
        var goTopBtn = document.querySelector('.arrow-scroll-to-top');
console.log(scrolled);
console.log(coords);
        if (scrolled > coords) {
            goTopBtn.classList.add('back_to_top-show');
        }
        if (scrolled < coords) {
            goTopBtn.classList.remove('back_to_top-show');
        }
    }

   backToTop() {
        if (window.pageYOffset > 0) {
            window.scrollBy(0, -80);
            setTimeout(this.backToTop, 0);
        }
    }
    render (){
        return(
            <button className="arrow-scroll-to-top visible-tablet" onClick={this._scrollToTop.bind(this)}></button>
        );      
    }
}