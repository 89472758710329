import React, { Component } from "react";
import { Nav, Row, Tab, Col} from "react-bootstrap";

import imgtab1 from '../assets/tab-1.png';
import imgtab2 from '../assets/tab-2.png';
import imgtab3 from '../assets/tab-3.png';
import imgtab4 from '../assets/tab-4.png';
import imgtabactive1 from '../assets/tab-1-active.png';
import imgtabactive2 from '../assets/tab-2-active.png';
import imgtabactive3 from '../assets/tab-3-active.png';
import imgtabactive4 from '../assets/tab-4-active.png';

export default class SpecializationBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative" id="specialization">
                    <div className="text-rotate">Специализация</div>
                    <div className="specialization__container">
                        <div className="containerBox">
                            <h2>Перевозка наливных грузов любого типа – качественно, быстро, по выгодной цене</h2>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={5}>
                                        <Nav className="flex-column my-2">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">
                                                    <img 
                                                        src={imgtab1}
                                                        width="39"
                                                        height="35"
                                                        alt="Опасные грузы"
                                                    />
                                                    <img 
                                                        src={imgtabactive1}
                                                        width="39"
                                                        height="35"
                                                        className="activeTab"
                                                        alt="Опасные грузы"
                                                    />
                                                    Опасные грузы
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">
                                                    <img 
                                                        src={imgtab2}
                                                        width="41"
                                                        height="52"
                                                        alt="Пищевые грузы"
                                                    />
                                                    <img 
                                                        src={imgtabactive2}
                                                        width="41"
                                                        height="52"
                                                        className="activeTab"
                                                        alt="Пищевые грузы"
                                                    />
                                                    Пищевые грузы
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">
                                                    <img 
                                                        src={imgtab3}
                                                        width="40"
                                                        height="56"
                                                        alt="Химические грузы"
                                                    />
                                                    <img 
                                                        src={imgtabactive3}
                                                        width="40"
                                                        height="56"
                                                        className="activeTab"
                                                        alt="Химические грузы"
                                                    />
                                                    Химические грузы
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fourth">
                                                    <img 
                                                        src={imgtab4}
                                                        width="40"
                                                        height="32"
                                                        alt="Наливные грузы"
                                                    />
                                                    <img 
                                                        src={imgtabactive4}
                                                        width="40"
                                                        height="32"
                                                        className="activeTab"
                                                        alt="Наливные грузы"
                                                    />
                                                    Наливные грузы
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={7}>
                                        <Tab.Content className="mt-2">
                                            <Tab.Pane eventKey="first">
                                                <p>Доставляем грузы различного класса опасности. Используем автоцистерны, 
                                                    оснащенные всем необходимым для транспортировки такого типа. 
                                                    Специалисты согласуют маршрут в уполномоченных инстанциях, 
                                                    оформят разрешения, организуют процесс доставки от начала и до конца.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <p>Перевозим растительные масла, животные жиры, патоку, мелассу и другие грузы. 
                                                    Контроль за состоянием груза в цистерне обеспечивают термометры, 
                                                    манометры, указатели уровня жидкости, клапаны сброса давления и 
                                                    другое оборудование. Сохранность товара полностью гарантирована.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <p>Организуем доставку химических веществ различного типа, в т.ч. кислот, 
                                                    щелочей, растворов солей, жидких окислителей и пероксидов, а также смолы, 
                                                    латекса (каучука), технических спиртов  и эфиров. Грузоперевозки 
                                                    выполняются в соответствии с требованиями ДОПОГ на специально 
                                                    оборудованном транспорте.</p>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <p>Доставляем все виды наливных грузов в цистернах, которые обладают 
                                                    специальными конструктивными особенностями, обусловленными 
                                                    химическими и физическими свойствами перевозимого вещества. 
                                                    Гарантирована сохранность груза. Строго соблюдаем сроки доставки и 
                                                    требования законодательства.</p>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}