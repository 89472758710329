import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import imgAdvantage1 from '../assets/middle-icon-1.png';
import imgAdvantage2 from '../assets/middle-icon-2.png';
import imgAdvantage3 from '../assets/middle-icon-3.png';
import imgAdvantage4 from '../assets/middle-icon-4.png';

export default class AdvantagesBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative" id="advantages">
                    <div className="text-rotate">Преимущества</div>
                    <div className="containerBox">
                        <div className="containerBox-874">
                            <Row>
                                <Col md="3" className="text-center">
                                    <img 
                                        src={imgAdvantage1}
                                        width="60"
                                        height="60"
                                        className="d-block m-auto"
                                        alt="imgAdvantage1"
                                    />
                                    <span>Все грузы застрахованы</span>
                                </Col>
                                <Col md="3" className="text-center">
                                    <img 
                                        src={imgAdvantage2}
                                        width="60"
                                        height="60"
                                        className="d-block m-auto"
                                        alt="imgAdvantage1"
                                    />
                                    <span>Собственный автопарк</span>
                                </Col>
                                <Col md="3" className="text-center">
                                    <img 
                                        src={imgAdvantage3}
                                        width="60"
                                        height="60"
                                        className="d-block m-auto"
                                        alt="imgAdvantage3"
                                    />
                                    <span>Отслеживание груза</span>
                                </Col>
                                <Col md="3" className="text-center">
                                    <img 
                                        src={imgAdvantage4}
                                        width="60"
                                        height="60"
                                        className="d-block m-auto"
                                        alt="imgAdvantage4"
                                    />
                                    <span>Собственные танк-контейнеры</span>
                                </Col>
                            </Row>
                            <p>Организуем автомобильные грузоперевозки на любые расстояния по России. 
                                Для транспортировки используется полностью исправный транспорт, 
                                который проходит техосмотр до и после каждого рейса. Услуги предоставляются 
                                частным лицам и корпоративным клиентам. Чтобы уточнить стоимость перевозки, 
                                узнать подробнее о сотрудничестве с нашей компанией, оставьте заявку на сайте. 
                                Менеджеры свяжутся с вами в ближайшее время. </p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}