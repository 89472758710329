import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import logo from '../assets/logo.png';

export default class Footer extends Component {
    render(){
        return (
            <>
                <footer className="position-relative">
                    <Container>
                        <Row>
                            <Col md="3">
                                    <img 
                                        src={logo}
                                        width="240"
                                        height="80"
                                        className="d-inline-block align-top footer-logo"
                                        alt="Logo"
                                    />
                            </Col>
                            <Col md="9">
                                <ul display="flex">
                                    <li><a href="#specialization">Услуги</a></li>
                                    <li><a href="#about">Информация</a></li>
                                    <li><a href="#">Конфиденциальность</a></li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3"></Col>
                            <Col md="9">
                                <div className="footer__bottom">
                                    <Row>
                                        <Col md="6">
                                            <p>© {this.currYear()} ИП РУдаков</p>
                                        </Col>
                                        <Col md="6">
                                            <p className="text-right"><a href="https://promo-links.ru/" target="_blank">Разработка сайта Promo links</a></p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>            
            </>
        )
    }

    currYear(){
        let d = new Date();
        return d.getFullYear();
    }
}