import './App.css';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header';
import HeaderTop from './Components/HeaderTop';
import Footer from './Components/Footer';
import MainPageBox from './Components/MainPageBox';
import MainPageBoxTop from './Components/MainPageBoxTop';
import ModalBox from "./Components/ModalBox";
import AppModalVertically from "./Components/ModalVertical";
import { YMInitializer } from 'react-yandex-metrika';
import ButtonScrollToTop from './Components/ButtonScrollToTop';

export default class App extends Component {

  render(){
    return(
      <div>
          <div className="main-box__top">
            <HeaderTop />
            <Header />
            <MainPageBoxTop />
          </div>
          <MainPageBox />
          <Footer />
          <YMInitializer accounts={[56197438]} options={{webvisor: true}} />
          <ButtonScrollToTop />
      </div>
    );
  }

}