import React, { Component } from "react";
import { Container, Nav, Row, Tab, Col } from "react-bootstrap";
import YandexMap from "./YandexMap";

export default class ContactsBox extends Component {
    render(){
        return (
            <>
                <div className="position-relative" id="contacts">
                    <div className="text-rotate">Контакты</div>
                    <div className="containerBoxFull">
                        <Row>
                            <Col md="5">
                                <ul>
                                    <li className="phone-mail">
                                        {/*<p><a href="tel:+7(812)635-02-35">+7(812)635-02-35</a></p>*/}
                                        <p><a href="mailto:info@rudakoffff.ru">info@rudakoffff.ru</a></p>
                                    </li>
                                    <li className="address">
                                        <p>197183, г. Санкт-Петербург, Липовая аллея, д. 9, лит. А , пом. 23Н</p>
                                    </li>
                                    <li className="registration"></li>
                                </ul>
                            </Col>
                            <Col md="7">
                                <YandexMap />
                               {/*<script type="text/javascript" charset="utf-8" async src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A8972a73fd2a0a7cdbfb40704582327015c2baf0d00518b0025d9a27569aa5b4e&amp;width=100%25&amp;height=500&amp;lang=ru_RU&amp;scroll=false"></script>*/}
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}