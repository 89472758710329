import React, { Component } from 'react';
import { Modal, Button, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";


export default class ModalBox extends Component {
    constructor(){
        super();
        this.state = {
            show: true
        }
    }

    handleModal(){
        console.log(this.state);
        this.setState({show:!this.state.show})
    }

  render(){
    return(
        <Modal show={this.state.show}>
            <Modal.Header closeButton>
                <Modal.Title>Оставить заявку</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FormGroup controlId="formModalEmail">
                        <FormControl type="email" placeholder="Email" />
                    </FormGroup>
                    <FormGroup controlId="formModalPhone">
                        <FormControl type="text" placeholder="Телефон" />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-wrap">
                    <Button variant="primary" type="submit" onClick={() => this.props.handleSubmit(this.state.name)}>Оставить заявку</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
  }
}